<template>
  <div>
    <Row class="expand-row">
      <Col span="6">
        <span className="expand-key">首次访问：</span>
        <span className="expand-value"> {{ row.add_time | formatDate }}</span>
      </Col>
      <Col span="6">
        <span className="expand-key">近次访问：</span>
        <span className="expand-value">{{ row.last_time | formatDate }}</span>
      </Col>
      <Col span="6">
        <span className="expand-key">身份证号：</span>
        <span className="expand-value">{{ row.card_id }}</span>
      </Col>
      <Col span="6">
        <span className="expand-key">真实姓名：</span>
        <span className="expand-value">{{ row.real_name }}</span>
      </Col>
    </Row>
    <Row class="expand-row">
      <Col span="6">
        <span className="expand-key">标签：</span>
        <span className="expand-value">{{ row.labels }}</span>
      </Col>
      <Col span="6">
        <span className="expand-key">生日：</span>
        <span className="expand-value">{{ row.birthday }}</span>
      </Col>
      <Col span="6">
        <span className="expand-key">推荐人：</span>
        <span className="expand-value">{{ row.spread_uid_nickname }}</span>
      </Col>
      <Col span="6">
        <span className="expand-key">地址：</span>
        <span className="expand-value">{{ row.addres }}</span>
      </Col>
    </Row>
    <Row class="expand-row">
      <Col span="6">
        <span className="expand-key">备注：</span>
        <span className="expand-value">{{ row.mark }}</span>
      </Col>
    </Row>
  </div>
</template>

<script>
import { formatDate } from '@/utils'

export default {
  name: 'table-expand',
  filters: {
    formatDate (time) {
      if (time !== 0) {
        let date = new Date(time * 1000)
        return formatDate(date, 'yyyy-MM-dd hh:mm')
      }
    }
  },
  props: {
    row: Object
  }
}
</script>

<style scoped>
.expand-row {
  margin-bottom: 16px;
}
</style>
